<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login -->
      <b-card class="mb-0">
        <div class="brand-logo">
          <img src="@/assets/images/multicedi-logo.svg" alt="logo" />
        </div>

        <b-card-title>
          <span>Verifica Cambio Password</span>
        </b-card-title>

        <b-card-text class="my-2">
          {{ feedback }}
        </b-card-text>

        <b-button block variant="primary" @click="$router.push('/login')"
          >Torna alla Home</b-button
        >
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCard,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
} from "bootstrap-vue";
import { Requests } from "@/api/requests.js";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCard,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
  },

  data: () => ({
    feedback: "",
  }),

  created() {
    this.passwordRecoveryVerify();
  },

  methods: {
    async passwordRecoveryVerify() {
      let data = new FormData();

      data.append("token", this.$route.query.token);
      data.append("action", "verify_token");

      try {
        const response = await Requests.passwordRecoveryVerify(data);
        this.feedback = response.lost_password_verify.feedback;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
